import React, {Component} from 'react';
import {Collapse, Nav, Navbar, NavbarBrand,NavbarToggler, NavItem, Button, NavLink} from 'reactstrap';
import {Link, Redirect, withRouter} from 'react-router-dom';
import checkIfUserIsLogged from './CheckIfLogged'

class AppNavbar extends Component{

    constructor(props)
    {
        super(props);
        
        this.toggle = this.toggle.bind(this);
        this.handleLogout = this.handleLogout.bind(this)
        this.state = {isOpen:false}
        
        fetch('/api/user/me', {
            method:'GET',
            headers:
                {
                    'credentials':'include'
                },
            credentials:'include'
        })
        .then(response => 
            {
                if(response.status==200)
                {
                    this.setState({logged: true})// = {isOpen:false, };
                }
                else
                {
                    this.setState({logged: false})// = {isOpen:false, };
                }
                
            }
            
        )
        .catch(function(error){
            console.log("ERRROOOOOR")
            console.log(error.message);
            this.setState({logged: false})
        })        
    }

    componentWillMount()
    {
        
    }
    handleLogout(event)
    {
        fetch('/perform_logout', {
            method:'GET',
            headers:
                {
                    //'Access-Control-Allow-Origin': 'http://beemed.xyz:7070',
                    //'no-cors':true,
                    //'credentials':'include'
                },
            credentials:'include'
        })
       window.location.href="/"
    }

    toggle()
    {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render()
    {
        
        let prikaz = null;
        if(this.state.logged==true)
        {
            
            prikaz = <Button onClick={this.handleLogout}>Izađi</Button>
        }
        else
        {
            prikaz= <Link className="btn btn-outline-success" to="/login">Prijavi se</Link>;
        }
        return(
        <Navbar color="dark" dark expand="md">
            <NavbarBrand tag = {Link}  to="/">Početna stranica</NavbarBrand>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        {prikaz}
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>)
    }
}

export default withRouter(AppNavbar);
