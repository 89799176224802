import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Modal, ModalHeader, ModalBody, Container, Form, FormGroup, Table, Label, Input} from 'reactstrap';
import AppNavbar from './AppNavbar';
import loggoutUser from './Logout';
import checkIfUserIsLogged from './CheckIfLogged'
import Autocomplete from  'react-autocomplete';
import toastr from 'toastr';
import './Autocomplete.css'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from './validator';

//matchDijagnozaOznaka

function matchDijagnozaOznaka(state, value) {
    let r = state.oznaka.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}
function matchDijagnozaNaziv(state, value) {
    let r = state.srp.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    return r;
}
function matchOznaka(state, value) {
    let r = state.oznaka.toString().toLowerCase().indexOf(value.toString().toLowerCase()) !== -1;
    return r;
}

function matchNaziv(state, value) {
    
    return (
        state.naziv.toLowerCase().indexOf(value.toLowerCase()) !== -1 //||
        //state.sifarnik_zdravstvenih_ustanova.naziv.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
}

 class StavkeChildComponent extends Component{

    constructor(props){
        super(props);

        //this.matchOznaka = this.matchOznaka.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateInputs = this.validateInputs.bind(this);


        

        let izvjestaj_obj = {
            stavke:[]
        };
        this.state=({})

        
    }

    validateInputs(event)
    {
    }

    handleInputChange(event)
    {
        let val = event.target.value;
        let name = event.target.name;
        let izv = {... this.state.izvjestaj};
        console.log("PODACI: ");
        console.log(val);
        console.log(name);
        console.log(izv);
        izv[name]=val;
        this.setState({izvjestaj:izv});
    }
    componentDidMount()
    {
        
    }
    componentWillMount()
    {

    }
    

    render()
    {
        
        return(
            
            <Container className="bg-light">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Puna oznaka</th>
                                        <th>Naziv</th>
                                        <th>Bodova</th>
                                        <th>Participacija</th>
                                        <th>Obrisi?</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr>
                                        <td>asd</td>
                                        <td>qwe</td>
                                        <td>asd</td>
                                        <td>zxc</td>
                                        <th><Button>Obrisi</Button></th>
                                    </tr>
                                    <tr>
                                        <td>asd</td>
                                        <td>qwe</td>
                                        <td>asd</td>
                                        <td>zxc</td>
                                        <th><Button>Obrisi</Button></th>
                                    </tr>
                                    <tr>
                                        <td>asd</td>
                                        <td>qwe</td>
                                        <td>asd</td>
                                        <td>zxc</td>
                                        <th><Button>Obrisi</Button></th>
                                    </tr>
                                    <tr>
                                        <td>asd</td>
                                        <td>qwe</td>
                                        <td>asd</td>
                                        <td>zxc</td>
                                        <th><Button>Obrisi</Button></th>
                                    </tr>
                                </tbody>
                            </Table>
                            <Button>Dodaj novu stavku</Button>
            </Container>
        );
    }
}

export default StavkeChildComponent;
//export default