export default  function validator(izvjestaj, toast)
{
    let all_right=true;
    /*
    Object.keys(izvjestaj).map((key, value) =>
        {
            console.log("vals")
            console.log(key);
            //console.log(value);
            toast.error(key +" : "+izvjestaj[key], {
                position: toast.POSITION.TOP_RIGHT
              });
            //let retObj = validate(this.retObjMap[key]['value'], this.retObjMap[key]['validators']);
            //this.retObjMap[key]['isValid']=retObj['didPass'];
            //this.retObjMap[key]['validationMessage']=retObj['message'];
            //if(retObj['didPass']==false)
            //{
            //    allValid=false;
            //}
        });*/

    if(izvjestaj['osiguranik_jmb']==null || izvjestaj['osiguranik_jmb'].trim()=='')
    {
        toast.error("Polje 'Jmb osiguranika' mora biti popunjeno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
        if(izvjestaj['osiguranik_jmb'].match(/^\d+$/))
        {

        }
        else{
            toast.error("Polje 'Jmb osiguranika' se mora sastojati samo od brojeva!", {
                position: toast.POSITION.TOP_RIGHT
              });
              all_right=false;
        }
        if(izvjestaj['osiguranik_jmb'].length!=13)
        {
            toast.error("Polje 'Jmb osiguraonika' mora biti dužine 13 karaktera!", {
                position: toast.POSITION.TOP_RIGHT
              });
              all_right=false;
        }
    
    if(izvjestaj['broj']==null ||izvjestaj['broj'].trim()=='')
    {
        toast.error("Polje 'Broj fakture' mora biti popunjeno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
        if(izvjestaj['broj'].match(/^\d+$/))
        {

        }
        else{
            toast.error("Polje 'Broj fakture' se mora sastojati samo od brojeva!", {
                position: toast.POSITION.TOP_RIGHT
              });
              all_right=false;
        }
    
    if(izvjestaj['ustanova_uputilac_sifra']==null || izvjestaj['ustanova_uputilac_sifra'].trim()=='')
    {
        toast.error("Polje 'Šifra ustanove uputioca' mora biti popunjeno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
        if(izvjestaj['ustanova_uputilac_sifra'].match(/^\d+$/))
        {

        }
        else{
            toast.error("Polje 'Šifra ustanove uputioca' se mora sastojati samo od brojeva!", {
                position: toast.POSITION.TOP_RIGHT
              });
              all_right=false;
        }
        if(izvjestaj['ustanova_uputilac_sifra'].length!=5)
        {
            toast.error("Polje 'Šifra ustanove uputioca' mora biti duzine 5 karaktera!", {
                position: toast.POSITION.TOP_RIGHT
              });
              all_right=false;
        }
    
    if(izvjestaj['ljekar_oznaka']==null || izvjestaj['ljekar_oznaka'].trim()=='')
    {
        toast.error("Polje 'Ljekar uputioc oznaka' mora biti popunjeno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
        if(izvjestaj['ljekar_oznaka'].length >= 3 && izvjestaj['ljekar_oznaka'].length <= 5)
        {
        }
        else{
            toast.error("Polje 'Ljekar uputioc oznaka' mora od 3 do 5 karaktera duzine.", {
                position: toast.POSITION.TOP_RIGHT
              });
              all_right=false;
        }
    
    if(izvjestaj['datum']==null || izvjestaj['datum'].trim()=='')
    {
        toast.error("Polje 'Datum' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['rok_placanja']==null || izvjestaj['rok_placanja'].trim()=='')
    {
        toast.error("Polje 'Rok placanja' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['broj']==null || izvjestaj['broj'].trim()=='')
    {
        toast.error("Polje 'Broj' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['ustanova_uputilac_naziv']==null || izvjestaj['ustanova_uputilac_naziv'].trim()=='')
    {
        toast.error("Polje 'Naziv ustanvoe uputioca' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['uputnica_tip']==null || izvjestaj['uputnica_tip'].trim()=='')
    {
        toast.error("Polje 'Uputnica tip' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['ljekar_ime_prezime']==null || izvjestaj['ljekar_ime_prezime'].trim()=='')
    {
        toast.error("Polje 'Ime i prezime ljekara uputioca' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['dijagnoza_oznaka']==null || izvjestaj['dijagnoza_oznaka'].trim()=='')
    {
        toast.error("Polje 'Dijagnoza oznaka' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['dijagnoza_naziv']==null || izvjestaj['dijagnoza_naziv'].trim()=='')
    {
        toast.error("Polje 'Dijagnoza naziv' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['lijecenje_period_od']==null || izvjestaj['lijecenje_period_od'].trim()=='')
    {
        toast.error("Polje 'Lijecenje period od' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['lijecenje_period_do']==null || izvjestaj['lijecenje_period_do'].trim()=='')
    {
        toast.error("Polje 'Lijecenje period do' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['osiguranik_ime_prezime']==null || izvjestaj['osiguranik_ime_prezime'].trim()=='')
    {
        toast.error("Polje 'Ime i prezime osiguranika' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['participacija_placa']==null || izvjestaj['participacija_placa'].trim()=='')
    {
        toast.error("Polje 'Participaciju placa' mora biti uneseno!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }
    if(izvjestaj['stavke']==null || izvjestaj['stavke'].length<1)
    {
        toast.error("Barem jedna stavka/usluga mora biti unesena!", {
            position: toast.POSITION.TOP_RIGHT
          });
          all_right=false;
    }

    return all_right;
}
