import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Modal, ModalHeader, ModalBody, Container, Form, FormGroup, Table, Label, Input} from 'reactstrap';
import AppNavbar from './AppNavbar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Login extends Component{

    handleNameInputChange(event)
    {
        const target = event.target;
        const value = target.value;
        const name = target.id;
        this.setState({username:value});
    }

    handlePassInputChange(event)
    {
        const target = event.target;
        const value = target.value;
        const name = target.id;
        this.setState({password:value});
    }

    constructor(props){
        super(props);
        this.handleNameInputChange = this.handleNameInputChange.bind(this);
        this.handlePassInputChange = this.handlePassInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state={username:"", password:""};
    }

    

    

    handleSubmit(event)
    {
        event.preventDefault();
        fetch('/perform_login', {
                method:'POST',
                headers:
                    {
                        
                        'Accept': 'application/x-www-form-urlencoded',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        credentials:'include'
                    },
                credentials:'include',
                body: "username="+this.state.username+"&password="+this.state.password,
            })
            .then(response => {
                if(response.status==200)
                {
                    this.props.history.push('/Izvjestaji');
                }
                else
                {
                    toast.error("Korisničko ime ili šifra nisu validni!", {
                        position: toast.POSITION.TOP_RIGHT
                      });
                    return;
                }
            })
            .catch(error =>{
                toast.error("Problem pri povezivanju sa serverom! Provjerite internet konekciju a potom pozovite programere!", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                console.log(error);
                console.log(error.message);
            })
    }
    componentDidMount()
    {

    }
    

    render()
    {
        return(
            <div className="bg-light" width="100%" height="100%" contentContainerStyle={{ flexGrow: 1 }} style={{ margin:0, height:'100%', width:'100%', position:'absolute', justifyContent:'center', alignItems:'center', }}>
                <AppNavbar/>
                <ToastContainer autoClose={8000} />
                <h1>Prijava</h1>
                <Container className="bg-light">
                    <Form  onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label className="d-inline p-0 h-100 font-weight-bold">Korisničko ime:</Label>
                            <Input className=""  type="text" name="username-input" id="username-input" value={this.state.username} onChange={this.handleNameInputChange} onBlur={this.handleNameInputChange}></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label className="d-inline p-0 h-100 font-weight-bold">Korisnička šifra:</Label>
                            <Input className=""  type="password" name="password-input" id="password-input" value={this.state.password} onChange={this.handlePassInputChange} onBlur={this.handlePassInputChange}></Input>
                        </FormGroup>
                        <FormGroup>
                                <Button className="btn btn-success"  type="submit">Prijavi se</Button>{'  '}
                                <Link className="btn btn-outline-danger" to="/">Odustani</Link>
                        </FormGroup>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default Login;