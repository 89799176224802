import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Container} from 'reactstrap';
import AppNavbar from './AppNavbar';
import Login from './Login'
import checkIfUserIsLogged from './CheckIfLogged'


class Home extends Component{
    constructor(props)
    {
        super(props);

        this.state={'logged':checkIfUserIsLogged()}
    }
    
    render()
    {
        

        return (
            <div className="bg-light" width="100%" height="100%" contentContainerStyle={{ flexGrow: 1 }} style={{ margin:0, height:'100%', width:'100%', position:'absolute', justifyContent:'center', alignItems:'center', }}>
                <AppNavbar/>
                <Link style={{display: 'flex',  justifyContent:'center', alignItems:'center', }} className="btn btn-dark" to="/login">Prijavi se</Link>
                    <Container  className="bg-light" >
                        <div className="row bg-light" height="240px" width="240px"> 
                            <h1>XML Alat</h1>
                            
                            
                        </div>
                    </Container>
                    <p>Za sva pitanja, obratite se na: kontakt [AT] fakture [DOT] info </p>
            </div>
        )
    }
}
export default Home;
//export default withLocalize(LanguageToggle);