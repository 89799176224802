import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './Login'
import Home from './Home.js'
import TestOK from './TestOK.js'
import AppNavbar from './AppNavbar';
import Izvjestaji from './Izvjestaji'

class App extends Component {
  constructor(props)
  {
    super(props);
  }
  render()
  {
    return(
          <Router className="bg-dark">
            <Switch className="bg-dark">
              <Route path="/" exact={true} component={Home}/>
              <Route path='/login' exact={true} component={Login}/>
              <Route path='/TestOK' exact={true} component={TestOK}/>
              <Route path='/Izvjestaji' exact={true} component={Izvjestaji}/>
              <Route path='/AppNavbar' exact={true} component={AppNavbar}/>
            </Switch>
          </Router>
    )//
  }
}

export default App;
